import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import React from "react";

function ContactPage() {
  return (
    <Container>
      <Typography variant="h3" fontWeight={"bold"} my={2}>
        Contacto IDE
      </Typography>
      <Card elevation={8} sx={{ borderRadius: "1rem" }}>
        <CardContent>
          <Box>
            <Typography variant="h6" component={"p"} fontWeight={"bold"}>
              IDE, Ideas en Desarrollo
            </Typography>
            <Typography variant="h6" component={"p"} fontWeight={"bold"} mb={2}>
              Relacion con Inversionistas
            </Typography>
            <Typography variant="h6" component={"p"} fontWeight={"bold"} mb={2}>
              <Link href={"tel:5555206575"}>55 5520-6575</Link>
            </Typography>
            <Typography variant="h6" component={"p"} fontWeight={"bold"}>
              Contacto:{" "}
              <Link href="mailto:fbraun@ide.fund">fbraun@ide.fund</Link>
            </Typography>
            <Typography
              variant="h6"
              component={"p"}
              fontWeight={"bold"}
              ml={10}
            >
              <Link href="mailto:ibecerril@ide.fund">ibecerril@ide.fund</Link>
            </Typography>
            <Divider></Divider>
            <Typography variant="h6" component={"p"} fontWeight={"bold"}>
              Dirección
            </Typography>
            <Typography variant="h6" component={"p"} fontWeight={"bold"}>
              Av. Paseo de la Reforma No. 2620, Edificio Reforma Plus, PH2,
              Colonia Lomas Altas, C.P. 11950, Alcaldía Miguel Hidalgo, Ciudad
              de México, México.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default ContactPage;
