import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { firebaseApp } from './initFirebase';

const KEY = 'BPSs6J-mHfIoQ-lBjjGupVhrcQ37wR1FM_4Zcykw7LYrH7T4tPZXxtmmNPZ3coE_57txzoXSr6y7MTWBTkZKEqw';

const messaging =  isSupported() ? getMessaging(firebaseApp) : null;

const generateTokenMessaging = () => new Promise((resolve, reject) =>
    getToken(messaging, { vapidKey: KEY })
        .then(fcmToken => resolve(fcmToken))
        .catch(error => reject(error))
)

export {
    messaging,
    generateTokenMessaging
}





