import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginAdmin, getMe, forgotPasswordApi, forgotPasswordChangeApi } from '../api/authApi';
import { clearToken, setMe, setToken } from '../slices/authSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';


export const login = createAsyncThunk('auth/login', ({ user, password, callback }, { dispatch }) => {
  dispatch(startLoading());

  loginAdmin(user, password)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => {
    const expires = new Date();
    expires.setHours(expires.getHours() + 10);
    dispatch(setToken({ token: data.token }));
    window.localStorage.setItem('token', data.token);
    window.localStorage.setItem('expires', Number(expires));
  })
  .then(() => callback())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const forgotPassword = createAsyncThunk('auth/forgotPassword', ({ user, callback }, { dispatch }) => {
  console.log("CALL FORGOTPASSWORDAction");
  dispatch(startLoading());

  forgotPasswordApi(user)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => {/* 
    const expires = new Date();
    expires.setHours(expires.getHours() + 10);
    dispatch(setToken({ token: data.token }));
    window.localStorage.setItem('token', data.token);
    window.localStorage.setItem('expires', Number(expires)); */
  })
  .then(() => callback())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const forgotPasswordChange = createAsyncThunk('auth/forgotPasswordChange', ({ user, token, password, callback }, { dispatch }) => {
  console.log("CALL FORGOTPASSWORDAction");
  dispatch(startLoading());

  forgotPasswordChangeApi(user, token, password)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => {/* 
    const expires = new Date();
    expires.setHours(expires.getHours() + 10);
    dispatch(setToken({ token: data.token }));
    window.localStorage.setItem('token', data.token);
    window.localStorage.setItem('expires', Number(expires)); */
  })
  .then(() => callback())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});



export const logout = createAsyncThunk('auth/logout', ({ callback }, { dispatch }) => {
  dispatch(startLoading());

  dispatch(clearToken());
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('expires');
  dispatch(endLoading());
  callback();
});

export const getmeactions = createAsyncThunk('auth/getmeactions', (_, { dispatch }) => {
  dispatch(startLoading());
const token = window.localStorage.getItem('token');

  getMe(token)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => {
    dispatch(setMe({ me: data.me}));
  })
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

