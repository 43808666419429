import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  meta: {
    last_updated_at: "2024-09-05T23:59:59Z",
  },
  data: {
    MXN: {
      code: "MXN",
      value: 19.8769028749,
    },
  },
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setDollar: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    clearDollar: () => ({ token: null }),
  },
});

export const { setDollar, clearDollar } = currencySlice.actions;
export default currencySlice.reducer;
