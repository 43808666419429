import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  me: "Bienvenido",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, { payload }) => ({ ...state, token: payload.token }),
    setMe: (state, { payload }) => ({ ...state, me: payload.me }),
    clearToken: () => ({ token: null }),
  },
});

export const { setToken, clearToken, setMe } = authSlice.actions;
export default authSlice.reducer;
