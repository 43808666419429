import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  current: {
    associateRef: "",
    projectRef: "",
    credit: "",
  },
};

const investmentsSlice = createSlice({
  name: "investments",
  initialState,
  reducers: {
    setInvestmentsList: (state, { payload }) => ({
      ...state,
      list: [...payload.investments],
    }),
    setCurrentInvestment: (state, { payload }) => ({
      ...state,
      current: { ...payload.investment },
    }),
    resetInvestmentsState: () => ({ list: [], current: {} }),
  },
});

export const {
  setInvestmentsList,
  setCurrentInvestment,
  resetInvestmentsState,
} = investmentsSlice.actions;
export default investmentsSlice.reducer;
