import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { currencyFormatter } from "../../../../utils/formatUtils";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import MobileStepper from "@mui/material/MobileStepper";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";

const styles = {
  inputWrapper: {
    margin: "0.5rem 0rem",
    border: 2,
    backgroundColor: "#ffffff",
    borderRadius: 4,
  },
  inputWrapper2: {
    margin: "0.5rem 0rem",
    border: 0,
    backgroundColor: "#ffffff",
    borderRadius: 4,
    height: 70,
  },
  inputWrapper3: {
    margin: "0.5rem 0rem",
    border: 2,
    backgroundColor: "#ffffff",
    borderRadius: 4,
    minHeight: 50,
    display: "flex",
    justifyContent: "left",
    alignItem: "center",
    padding: "0px 10px",
  },
  inputWrapper4: {
    margin: "0.5rem 0rem",
    border: 2,
    backgroundColor: "#ffffff",
    borderRadius: 4,
    minHeight: 50,
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    boxShadow: 5,
    padding: "0px 10px",
  },
  submit: {
    textAlign: "center",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  resize: {
    fontSize: 50,
  },
  column: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
  },
};

const ProjectDetailsInfo = (props) => {
  let { id } = useParams();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 1;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const investments = Object.values(props.investments);
  const investmentToShow = investments.find((i) => i._id === id);
  const hoursToAdd = 6 * 60 * 60 * 1000;
  const date = new Date(investmentToShow?.investmentDate);

  return (
    <Card elevation={0}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
      </Box>
      <CardHeader
        subheaderTypographyProps={{ fontWeight: "bold" }}
        sx={{ backgroundColor: "#3D6076", borderRadius: 20 }}
        title="Datos de la inversión"
      />
      <CardContent sx={{ px: 0 }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={12} sx={styles.column}>
            <Box className={"text-center"}>
              <Typography color="grey" fontSize={14}>
                Vehículo de inversión
              </Typography>
            </Box>
            <Box sx={styles.inputWrapper3}>
              {investmentToShow?.vehiculo == null ? (
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  ------------{" "}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {investmentToShow?.investor}
                </Typography>
              )}
            </Box>
            <Box className={"text-center"}>
              <Typography color="grey" fontSize={14}>
                Capital por aportar
              </Typography>
            </Box>
            <Box sx={styles.inputWrapper3}>
              {/* <Text>ㅤ</Text> */}
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {
                  currencyFormatter
                    .format(investmentToShow?.capital)
                    .split(".")[0]
                }{" "}
              </Typography>
            </Box>
            <Box className={"text-center"}>
              <Typography color="grey" fontSize={14}>
                Capital aportado a la fecha
              </Typography>
            </Box>
            <Box sx={styles.inputWrapper3}>
              {/* <Text>ㅤ</Text> */}
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {
                  currencyFormatter
                    .format(investmentToShow?.capitalNow || 0)
                    .split(".")[0]
                }{" "}
              </Typography>
            </Box>
            <Box className={"text-center"}>
              <Typography color="grey" fontSize={14}>
                Participación en el activo
              </Typography>
            </Box>
            <Box sx={styles.inputWrapper3}>
              {/* <Text>ㅤ</Text> */}
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {investmentToShow?.participation?.toFixed(2) ?? ""} %{" "}
              </Typography>
            </Box>
            <Box className={"text-center"}>
              <Typography color="grey" fontSize={14}>
                Fecha de inversión
              </Typography>
            </Box>
            <Box sx={styles.inputWrapper3}>
              {/* <Text>ㅤ</Text> */}
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {new Date(
                  date.setTime(date.getTime() + hoursToAdd)
                ).toLocaleDateString("en-GB", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })}
              </Typography>
            </Box>
            {maxSteps > 1 && (
              <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            )}
          </Grid>
          <Grid xs={12} item textAlign={"right"}>
            <Typography
              textAlign={"end"}
              sx={{
                fontStyle: "italic",
                marginBottom: "4em",
                marginRight: "1em",
              }}
            >
              {props.isUSD ? "*Montos en USD" : "*Montos en MXN"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          sx={{ marginTop: "5rem" }}
          container
          justifyContent="right"
          alignItems="right"
        ></Grid>
      </CardContent>
    </Card>
  );
};

export default ProjectDetailsInfo;
