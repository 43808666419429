import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../../pages/Login/Login";
import Dashboard from "../../pages/Dashboard/Dashboard";
import ProjectsPage from "../../pages/Dashboard/Projects/ProjectsPage";
import Reportes from "../../pages/Dashboard/Projects/ProjectDetails/Reportes";
import Forgot from "../../pages/Login/forgotpassword";
import Reset from "../../pages/Login/restablecerpass";
import ProjectDetailsPage from "../../pages/Dashboard/Projects/ProjectDetails/ProjectDetailsPage";
import ProfilePage from "../../pages/Dashboard/Profile/ProfilePage";
import ContactPage from "../../pages/Dashboard/Contact/ContactPage";
import ChangePasswordPage from "../../pages/Dashboard/Profile/ChangePassword/ChangePasswordPage";

const Router = () => {
  const token = useSelector((state) => state.auth.token);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={token ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route path="/login/forgotpassword" element={<Forgot />} />
        <Route path="/login/restablecerpass" element={<Reset />} />
        <Route path="/reportes" element={<Reportes />} />
        <Route path="/dashboard" element={<ProjectsPage />} />
        <Route
          path="/dashboard/*"
          element={token ? <Dashboard /> : <Navigate to="/login" />}
        >
          <Route index element={<Navigate to="proyectos" />} />
          <Route path="proyectos" element={<ProjectsPage />} />
          <Route path="contacto" element={<ContactPage />} />
          <Route path="perfil" element={<ProfilePage />} />
          <Route
            path="perfil/cambio-contraseña"
            element={<ChangePasswordPage />}
          />

          <Route path="proyectos/:id" element={<ProjectDetailsPage />} />
          <Route path="*" element={<Navigate to="proyectos" />} />
        </Route>
        <Route
          path="*"
          element={
            token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
