import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  investor: [],
  current: {},
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReportsList: (state, { payload }) => ({
      ...state,
      list: [...payload.reports],
    }),
    setInvestorReportsList: (state, { payload }) => ({
      ...state,
      investor: [...payload.reports],
    }),
    setCurrentReport: (state, { payload }) => ({
      ...state,
      current: { ...payload.report },
    }),
    resetReportsState: () => ({ list: [], investor: [], current: {} }),
  },
});

export const {
  setReportsList,
  setInvestorReportsList,
  setCurrentReport,
  resetReportsState,
} = reportsSlice.actions;
export default reportsSlice.reducer;
