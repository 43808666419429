import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ProfilePage() {
  const user = useSelector((state) => state.auth.me);
  const navigate = useNavigate();

  const handleButton = () => {
    navigate("/dashboard/perfil/cambio-contraseña");
  };

  console.log({ user });
  return (
    <Container>
      <Typography variant="h3" fontWeight={"bold"} my={2}>
        Usuario
      </Typography>
      <Card elevation={4} sx={{ borderRadius: "1rem" }}>
        <CardContent>
          <Box display={"flex"}>
            <Typography variant="h5" fontWeight={"bold"} flex={1} flexGrow={1}>
              Información de contacto
            </Typography>
            {/* <Button color="secondary" sx={{ my: 0, borderRadius: "0.5rem" }}>
              Editar
            </Button> */}
          </Box>
          <Typography variant="h6" component={"p"} fontWeight={"bold"}>
            Nombre:
            <Typography
              variant="h6"
              component={"span"}
              fontWeight={"regular"}
              ml={1}
            >
              {user?.name ?? "-"}
            </Typography>
          </Typography>
          <Grid2 container columnSpacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Typography variant="h6" component={"p"} fontWeight={"bold"}>
                Usuario:
                <Typography
                  variant="h6"
                  component={"span"}
                  fontWeight={"regular"}
                  ml={1}
                >
                  {user?.user ?? "-"}
                </Typography>
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Typography variant="h6" component={"p"} fontWeight={"bold"}>
                Correo:{" "}
                <Typography
                  variant="h6"
                  component={"span"}
                  fontWeight={"regular"}
                  ml={1}
                >
                  {user?.email ?? "Correo"}
                </Typography>
              </Typography>
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
      <Box display={"flex"} justifyContent={"end"}>
        <Button onClick={handleButton}>Cambiar contraseña</Button>
      </Box>
    </Container>
  );
}

export default ProfilePage;
