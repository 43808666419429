import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  current: {},
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjectsList: (state, { payload }) => ({
      ...state,
      list: [...payload.projects],
    }),
    setCurrentProject: (state, { payload }) => ({
      ...state,
      current: { ...payload.project },
    }),
    resetProjectsState: () => ({ list: [], current: {} }),
  },
});

export const { setProjectsList, setCurrentProject, resetProjectsState } =
  projectsSlice.actions;
export default projectsSlice.reducer;
