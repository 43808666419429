import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import ProjectDetailsInfo from "./ProjectDetailsInfo";
import ProjectDetailsDescription from "./ProjectDetailsDescription";
import ProjectDetailsReports from "./ProjectDetailsReports";
import ProjectDetailsFinanceInfo from "./ProjectDetailsFinanceInfo";
import ProjectDetailsInvestorReports from "./ProjectDetailsInvestorReports";
import ProjectDetailsDistributions from "./ProjectDetailsDistributions";
import ProjectDetailsInterests from "./ProjectDetailsInterests";

import { resetProjectsState } from "../../../../slices/projectsSlice";
import {
  getInvertorReportsList,
  getReportsList,
} from "../../../../actions/reportsActions";
import { getCurrentInvestment } from "../../../../actions/investmentsActions";
import { getDistributionsList } from "../../../../actions/distributionsActions";
import { getInterestsList } from "../../../../actions/interestsActions";
import { ProjectAppbar } from "../ProjectAppbar";

const styles = {
  columns: {
    px: "1rem",
    my: "1rem",
  },
};

const ProjectDetailsPage = () => {
  const { id: investmentId } = useParams();

  const dispatch = useDispatch();
  const current = useSelector((state) => state.investments.current);
  const [distributionId, setDistributionId] = useState("");

  const [modals, setModals] = useState({
    reports: false,
    finance: false,
    investor: false,
  });

  useEffect(() => {
    dispatch(getCurrentInvestment({ id: investmentId }));
    return () => dispatch(resetProjectsState());
  }, []);

  useEffect(() => {
    if (current[0]?.projectRef?._id) {
      dispatch(
        getDistributionsList({
          query: {
            projectRef: current[0].projectRef._id,
            associateInvestmentId: investmentId,
          },
        })
      );
      dispatch(
        getInterestsList({
          query: {
            projectRef: current[0].projectRef._id,
            associateInvestmentId: investmentId,
          },
        })
      );
      dispatch(
        getReportsList({ query: { projectRef: current[0].projectRef._id } })
      );
      dispatch(
        getInvertorReportsList({
          investorId: current[0].associateRef?._id,
          projectId: current[0].projectRef._id,
          associateInvestmentId: investmentId,
        })
      );
    }
  }, [current]);

  const handleOpenModal = (modal) => setModals({ ...modals, [modal]: true });
  const handleCloseModal = (modal) => setModals({ ...modals, [modal]: false });
  console.log("current");
  console.log(current);

  const isSmallScreen = useMediaQuery("(min-width:600px)");

  const handleInfoReport = () => {
    handleOpenModal("reports");
  };

  const handleFinancialInfo = () => {
    handleOpenModal("finance");
  };

  return (
    <>
      {isSmallScreen && (
        <ProjectAppbar
          investments={current}
          onOpen={handleOpenModal}
          isUSD={current[0]?.projectRef?.isUSD}
        />
      )}
      {!isSmallScreen && (
        <Grid
          container
          justifyContent={"center"}
          bgcolor={"#3D6076"}
          padding="0 0"
        >
          <Grid item xs={6}>
            <Button fullWidth variant="text" onClick={handleInfoReport}>
              <Typography variant="subtitle2" color="white" component="div">
                Reportes Informativos
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="text" onClick={handleFinancialInfo}>
              <Typography variant="subtitle2" color="white" component="div">
                Información Financiera
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container px={{ xs: 1, md: 0 }}>
        <Grid item xs={12} md={7} lg={6} sx={styles.columns}>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{
              marginTop: "0.5rem",
              marginBottom: "-0.5rem",
              color: "#3C3C3B",
            }}
          >
            {current[0]?.projectRef?.name ?? investmentId}
          </Typography>
          <ProjectDetailsDescription {...current[0]?.projectRef} />
        </Grid>
        <Grid item xs={12} md={5} lg={6} sx={styles.columns}>
          <ProjectDetailsInfo
            investments={current}
            onOpen={handleOpenModal}
            isUSD={current[0]?.projectRef?.isUSD}
          />
        </Grid>
        <Grid item xs={12} sx={{ my: "-5rem", marginBottom: "1rem" }}>
          <ProjectDetailsDistributions
            onOpen={handleOpenModal}
            setDistributionId={setDistributionId}
          />
        </Grid>
        <Grid
          sx={{ marginTop: "1rem" }}
          container
          justifyContent="left"
          alignItems="right"
        >
          <Typography textAlign={"left"} sx={{ fontStyle: "italic" }}>
            {current[0]?.projectRef?.isUSD
              ? "*Montos en USD"
              : "*Montos en MXN"}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ my: "5rem", marginBottom: "1rem" }}>
          <ProjectDetailsInterests />
        </Grid>
        <Grid
          sx={{ marginTop: "1rem" }}
          container
          justifyContent="right"
          alignItems="right"
        ></Grid>
      </Grid>
      <ProjectDetailsReports
        open={modals.reports}
        onClose={() => handleCloseModal("reports")}
      />
      <ProjectDetailsFinanceInfo
        open={modals.finance}
        onClose={() => handleCloseModal("finance")}
      />
      <ProjectDetailsInvestorReports
        open={modals.investor}
        onClose={() => handleCloseModal("investor")}
        distributionId={distributionId}
      />
    </>
  );
};

export default ProjectDetailsPage;
