import { useSelector } from "react-redux";
import { currencyFormatter } from "../../../../utils/formatUtils";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
} from "victory";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

function findDocumentById(obj, id) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]._id === id) {
      return obj[key];
    }
  }
  return null;
}

const ProjectDetailsGraph = () => {
  let { id } = useParams();
  const distributions = useSelector((state) => state.distributions.list);
  const investments = useSelector((state) => state.investments.current);
  const current = findDocumentById(investments, id);

  let [dna, setDna] = useState(0);

  useEffect(() => {
    if (distributions < 1) return;
    let newDna = distributions[distributions.length - 1].netAccumulated;
    setDna(newDna);
  }, [distributions]);

  let data = null;

  if (distributions.length > 0) {
    data = [
      {
        quarter: 3,
        earnings: current?.capital || 0,
        fill: "#385723",
        label: currencyFormatter.format(current?.capital || 0).split(".")[0],
      },
      {
        quarter: 2,
        earnings: current?.capitalNow || 0,
        fill: "#3D6076",
        label: currencyFormatter.format(current?.capitalNow || 0).split(".")[0],
      },
      {
        quarter: 1,
        earnings: dna || 0,
        fill: "#470055",
        label: currencyFormatter.format(dna || 0).split(".")[0],
      },
    ];
  } else {
    data = [
      {
        quarter: 3,
        earnings: current?.capital || 0,
        fill: "#385723",
        label: currencyFormatter.format(current?.capital || 0).split(".")[0],
      },
      {
        quarter: 2,
        earnings: current?.capitalNow || 0,
        fill: "#3D6076",
        label: currencyFormatter.format(current?.capitalNow || 0).split(".")[0],
      },
      {
        quarter: 1,
        earnings: dna || 0,
        fill: "#470055",
        label: currencyFormatter.format(0).split(".")[0],
      },
    ];
  }

  return (
    <>
      <>
        <VictoryChart
          height={160}
          width={300}
          padding={{ top: 20, left: 65, right: 35, bottom: 50 }}
          // adding the material theme provided with Victory
          theme={VictoryTheme.material}
          domainPadding={15}
        >
          <VictoryAxis
            tickLabelComponent={
              <VictoryLabel verticalAnchor="middle" textAnchor="start" x={0} />
            }
            style={{
              grid: { stroke: ({ tick }) => (tick > 0.5 ? "#ffffff" : "grey") },
              tickLabels: { fontSize: 7.5, fontFamily: "Raleway, Arial" },
            }}
            tickValues={[3, 2, 1]}
            tickFormat={[
              "Capital por\n aportar",
              "Capital aportado\n a la fecha",
              "Distribuciones",
            ]}
          />
          <VictoryBar
            barWidth={20}
            cornerRadius={{ topRight: 5, topLeft: 5 }}
            style={{
              data: {
                fill: ({ datum }) => {
                  return datum.quarter === 1
                    ? "#C6C6C6"
                    : datum.quarter === 2
                    ? "#3C3C3B"
                    : "#21638E";
                },
              },
              labels: { fontSize: 6, fontFamily: "Raleway, Arial" },
            }}
            horizontal
            data={data}
            x="quarter"
            y="earnings"
          />
        </VictoryChart>
      </>
    </>
  );
};

export default ProjectDetailsGraph;
