import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInvestments, getInvestment, createInvestment } from '../api/investmentsApi';
import { setInvestmentsList, setCurrentInvestment } from '../slices/investmentsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getInvestmentsList = createAsyncThunk('investments/getInvestmentsList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getInvestments(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setInvestmentsList({ investments: data.investments })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentInvestment = createAsyncThunk('investments/getCurrentInvestment', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getInvestment(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentInvestment({ investment: data.investment })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const createNewInvestment = createAsyncThunk('investments/createNewInvestment', ({ id, investment, query, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createInvestment(token, id, investment)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(() => callback())
  .then(() => dispatch(getInvestmentsList({ query })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
