import { TableRow, TableCell } from "@mui/material";
import { Link } from "react-router-dom";
import { currencyFormatter } from "../../../../utils/formatUtils";

const ProjectsListItem = (props) => {
  return (
    <TableRow>
      <TableCell sx={{ textAlign: "left" }}>
        <Link to={props._id}>{props.projectRef?.name}</Link>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <Link to={props._id}>{props.investor}</Link>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {new Date(props.projectRef?.investmentDate)?.toLocaleString("en-GB", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {props.projectRef.isInvestmentUSD ? "USD" : "MXP"}
      </TableCell>
      <TableCell sx={{ textAlign: "right" }}>
        {currencyFormatter.format(props.capital).split(".")[0]}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {props.participation ?? "-"}%
      </TableCell>
    </TableRow>
  );
};

export default ProjectsListItem;
