import { TableRow, TableCell } from "@mui/material";
import { currencyFormatter } from "../../../../utils/formatUtils";
import { Box, Button } from "@mui/material";

const ProjectsListItem = (props) => {
  return (
    <TableRow>
      <TableCell sx={{ textAlign: "center" }}>
        <Box>
          <Button
            fullWidth
            color="primary"
            onClick={() => props.onOpen("finance")}
          >
            Información financiera
          </Button>
        </Box>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {new Date(props.projectRef?.investmentDate)?.toLocaleString()}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {currencyFormatter.format(props.projectRef?.investment).split(".")[0]}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {props.projectRef?.percentageIde ?? "-"}%
      </TableCell>
    </TableRow>
  );
};

export default ProjectsListItem;
