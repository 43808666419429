import { createAsyncThunk } from '@reduxjs/toolkit';
import {  saveToken } from '../api/notificationsApi';
import { handleError } from '../slices/errorsSlice';

export const updateToken = createAsyncThunk('notifications/updateToken', ({ id, notification }, { getState, dispatch }) => {
  const { token } = getState().auth;
  
  saveToken(token, id, notification)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .catch(err => dispatch(handleError(err)))
});
