import { Box } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const { REACT_APP_HOST_URL } = process.env;

const styles = {
  carousel: {
    height: '350px'
  },
  slide: {
    height: '350px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  fab: {
    position: 'absolute',
    padding: '0.5rem',
    right: 0
  }
}

const ProjectDetailsImagesCarousel = props => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Splide options={ { rewind: true } } style={styles.carousel}>
        {props.images?.length > 0 ? props.images.map(image =>
          <SplideSlide key={image._id}>
            <Box sx={{ ...styles.slide, backgroundImage: `url(${REACT_APP_HOST_URL}/images/projects/${image.path})` }} />
          </SplideSlide>  
        ) : 
        <SplideSlide>
            <Box sx={{ ...styles.slide, backgroundImage: `url(/img/img-1.jpg)` }} />
        </SplideSlide>  
        }
      </Splide>
    </Box>
  );
}

export default ProjectDetailsImagesCarousel;
