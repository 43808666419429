import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#3D6076",
      light: "#15485c",
      dark: "#4b869c",
      contrastText: "#fff",
    },
    secondary: {
      main: "#707070",
      light: "#4e4e4e",
      dark: "#8c8c8c",
      contrastText: "#fff",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Raleway, Arial",
  },
});

const theme = createTheme({
  palette: { ...defaultTheme.palette },
  typography: {
    fontFamily: "FranklinGothic, Raleway, Arial",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "FranklinGothic, Raleway, Arial",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          fontFamily: "FranklinGothic, Raleway, Arial",
        },
      },
    },
    // MuiTableCell: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: "Raleway, Arial",
    //     },
    //   },
    // },
    MuiCard: {
      styleOverrides: {
        root: {
          fontFamily: "FranklinGothic, Raleway, Arial",
        },
      },
    },
    // MuiCardHeader: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: "Raleway, Arial",
    //       fontWeight: "bold",
    //     },
    //   },
    // },
    MuiSelect: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        disabled: true,
      },
      styleOverrides: {
        root: {
          fontFamily: "FranklinGothic, Raleway, Arial",
          marginTop: defaultTheme.spacing(1),
          marginBottom: defaultTheme.spacing(1),
          "& .Mui-disabled": {
            color: "black",
          },
          "& .MuiOutlinedInput-input.Mui-disabled": {
            "-webkit-text-fill-color": "unset",
          },
        },
      },
    },

    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          fontFamily: "FranklinGothic, Raleway, Arial",
          marginTop: defaultTheme.spacing(1.5),
          marginBottom: defaultTheme.spacing(1.5),
          borderRadius: "1rem",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontFamily: "FranklinGothic, Raleway, Arial",
          backgroundColor: defaultTheme.palette.primary.main,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: "FranklinGothic, Raleway, Arial",
          color: defaultTheme.palette.primary.contrastText,
          fontWeight: "bold",
        },
        root: {
          fontFamily: "FranklinGothic, Raleway, Arial",
          paddingBottom: defaultTheme.spacing(1),
          paddingTop: defaultTheme.spacing(1),
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.primary.main,
          color: defaultTheme.palette.primary.contrastText,
          textAlign: "center",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: "95vw",
        },
      },
    },
  },
});

export default theme;
