const { REACT_APP_API_BASE_URL } = process.env;

export const saveToken = (token, id, notification) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_API_BASE_URL + "/notifications/token/" + id,
      {
        method: "PUT",
        body: JSON.stringify({ notification }),
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
