import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, Typography, IconButton, Divider, Grid } from '@mui/material';
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';

import ReportItem from '../../../../components/items/ReportItem';

const styles = {
  container: {
    width:'100%',
    display: 'block',
  },
  content: {
    paddingY: 0,
    height: 525, width: '90vw'
  },
  actions: {
    paddingRight: '1rem'
  },
  title: {
    my: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    px: '1rem'
  }
}

const ProjectDetailsInvestorReports = props => {
  const reports = useSelector(state => state.reports.investor);

  const handleClose = e => {
    e?.preventDefault();
    props.onClose();
  }

  console.log(props)

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>
       <Box sx={styles.title}>
          <Typography variant="h5">Reportes distribuciones</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
        <Divider />
      </DialogTitle>
        <Grid container>
          <Grid item xs={12} sx={{ px:{xs:'.5rem', sm:'1rem', md:'1.5rem'}, pb: '1rem' }}>
            <TableContainer component={Paper} style={{backgroundColor: '#ffffff', borderRadius: 30}}>
              <Table>
                <TableHead sx={{ backgroundColor: '#3C3C3B' }}>
                  <TableRow>
                    <TableCell sx={{textAlign:'center'}}>Nombre del reporte</TableCell>
                    <TableCell sx={{textAlign:'center'}}>Reporte</TableCell>
                    <TableCell sx={{textAlign:'center'}}>Periodo</TableCell>
                    <TableCell sx={{textAlign:'center'}}>Fecha del reporte</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {reports.filter(r => r.distributionRef === props.distributionId).map(report => <ReportItem key={report._id} {...report} reportsInve={true} />)}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
    </Dialog>
  );
}

export default ProjectDetailsInvestorReports;
