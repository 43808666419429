export const getCurrencyDollar = () =>
  new Promise((resolve, reject) => {
    const now = new Date(Date.now());
    const lastTimeApi = window.localStorage.getItem("currencyTimestamp");
    const dif = now.getTime() - Number(lastTimeApi ?? now.getTime());
    // console.log({ lastTimeApi, now, dif });
    // console.log(dif < 60 * 60 * 1000 ? "Nuevo" : "Viejo");
    if (dif < 7 * 24 * 60 * 60 * 1000) {
      // console.log("Usando Viejo");
      try {
        const currency = JSON.parse(window.localStorage.getItem("currency"));
        // console.log({ currency });
        return resolve(currency);
      } catch (error) {
        console.error(error);
        return reject({ error: "Ups" });
      }
    }
    const request = new Request(
      "https://api.currencyapi.com/v3/latest?apikey=cur_live_CqTeTu15E81Gkdysecpgefyy6d1UgdQN9ZOLdNGj&currencies=MXN",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origi": "*",
        },
      }
    );
    console.log("Api Call");
    fetch(request)
      .then((request) => request.json())
      .then((response) => {
        // console.log({ response });
        window.localStorage.setItem("currency", JSON.stringify(response));

        window.localStorage.setItem(
          "currencyTimestamp",
          now.getTime().toString()
        );
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
