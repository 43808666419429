import { Paper, Grid } from '@mui/material';

const styles = {
  paper: {
    marginBottom: '1rem',
    border: 0,
    disableUnderline: true,
  },
  resize: {
    fontSize: 20,
    backgroundColor: '#ffffff', borderRadius: 10
  },
  header: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'around'
  },
  title: {
    flex: 1
  },
  container: {
    padding: 'rem',
    paddingTop: '1'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  textfieldr: {
    borderRadius: 40,
    [`& fieldset`]: {
      borderRadius: 80
    }
  }
}

const InformativosFilter = props => {

  return (
    <Paper sx={styles.paper}>
      <Grid container component="form" onSubmit={props.onSubmit} >
        <Grid item xs={4} sx={styles.inputWrapper}>
          <div class="input-group mb-3">
            <input style={styles.resize} type="text" class="form-control" placeholder="Búsqueda de reporte" aria-label="Búsqueda de reporte" aria-describedby="button-addon2" name="project" onChange={props.onChange}/>
              <button style={styles.resize}class="btn btn-outline-secondary" onSubmit={props.onSubmit} type="submit" id="button-addon2">Search</button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default InformativosFilter;
