import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import InformativoListItem from "../ProjectDetails/InformativoListItem";

const InformativoListTable = (props) => {
  return (
    <TableContainer
      component={Paper}
      style={{ backgroundColor: "#fafafa", borderRadius: 30 }}
    >
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell sx={{ textAlign: "center" }}>
              Nombre del Reporte
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              Fecha de inversión
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>
              Monto de Inversión
            </TableCell>
            <TableCell sx={{ textAlign: "center" }}>Participación</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.projects?.map((project) => (
            <InformativoListItem key={project._id} {...project} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InformativoListTable;
