import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { HomeWork } from "@mui/icons-material";

import { logout } from "../actions/authActions";

const items = [
  // { text: "Proyectos", icon: <HomeWork />, path: "/dashboard/proyectos" },
  { text: "Inversiones", icon: <HomeWork />, path: "/dashboard/proyectos" },
  {
    text: "Reportes informativos",
    icon: <HomeWork />,
    path: "/dashboard/proyectos",
  },
  {
    text: "Información financiera",
    icon: <HomeWork />,
    path: "/dashboard/proyectos",
  },
  { text: "Otros", icon: <HomeWork />, path: "/dashboard/proyectos" },
  { text: "Contacto", icon: <HomeWork />, path: "/dashboard/contacto" },
  { text: "Perfil usuario", icon: <HomeWork />, path: "/dashboard/perfil" },
];

const drawerWidth = 235;

const styles = {
  drawer: {
    width: drawerWidth - 5,
    flex: 1,
  },
  logoWrapper: {
    justifyContent: "center",
  },
  logo: {
    width: "50%",
    cursor: "pointer",
  },
  dividerWrapper: {
    marginY: "0.75rem",
    paddingX: "1rem",
  },
  buttonWrapper: {
    textAlign: "center",
    padding: "1rem",
  },
};

const AppDrawerNew = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (path) => () => {
    navigate(path);
    if (props?.isMobile ?? false) props.onClose();
  };

  const handleLogout = () => {
    dispatch(logout({ callback: () => navigate("/login") }));
  };

  return (
    <div>
      <Box sx={styles.drawer} role="presentation">
        <Box
          display={"flex"}
          flexDirection={"column"}
          height={"100vh"}
          width={"100%"}
        >
          <Box flex={1} flexGrow={1}>
            <List>
              <ListItem sx={styles.logoWrapper}>
                <img
                  src="/img/IDEGREY.png"
                  alt="IDE logo"
                  style={styles.logo}
                  onClick={handleNavigate("")}
                />
              </ListItem>
              <Box sx={styles.dividerWrapper}>
                <Divider />
              </Box>
              {items.map((item, index) => (
                <ListItemButton
                  key={`${item.text}-${index}`}
                  onClick={handleNavigate(item.path)}
                >
                  {/* <ListItemIcon>
                {item.icon}
              </ListItemIcon> */}
                  <ListItemText
                    primary={
                      <Typography
                        variant="h6"
                        display="block"
                        gutterBottom
                        sx={{
                          color: "gray",
                          ":hover": {
                            color: "#3D6076",
                          },
                        }}
                      >
                        {item.text}
                      </Typography>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </Box>
          <Box width={"100%"}>
            <ListItemButton selected onClick={handleLogout}>
              {/* <ListItemIcon>
                {item.icon}
              </ListItemIcon> */}
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    display="block"
                    gutterBottom
                    sx={{
                      color: "gray",
                      ":hover": {
                        color: "#3D6076",
                      },
                    }}
                  >
                    Cerrar sesión
                  </Typography>
                }
              />
            </ListItemButton>
            {/* <Box sx={styles.buttonWrapper}>
              
              <Button fullWidth variant="text" onClick={handleLogout}>
                Cerrar sesión
              </Button>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default AppDrawerNew;
