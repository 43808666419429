import {
  Box,
  Card,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { currencyFormatter } from "../../../../utils/formatUtils";
import { getTenYears } from "../../../../utils/getTenyears";

const COLORS = [
  "#9e0142",
  "#5e4fa2",
  "#d53e4f",
  "#3288bd",
  "#f46d43",
  "#66c2a5",
  "#fdae61",
  "#fee08b",
  "#e6f598",
  "#abdda4",
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Card sx={{ p: 1 }}>
        <Typography
          variant="caption"
          component={"p"}
          fontWeight={"bold"}
        >{`${payload[0].name}`}</Typography>
        <div>
          {payload.map((pld, index) => (
            <Typography
              variant="caption"
              component={"p"}
              fontWeight={"bold"}
              textAlign={"center"}
              key={`${pld.value}-${index}`}
            >
              {currencyFormatter.format(pld.value).split(".")[0]}
            </Typography>
          ))}
        </div>
      </Card>
    );
  }

  return null;
};

const years = getTenYears();

const ages = [
  {
    value: "Todos",
    label: "Todos",
  },
  ...years.map((data) => {
    return {
      value: data,
      label: data.toString(),
    };
  }),
];

function ProjectsPieChart({ data, isUSD }) {
  const [total, setTotal] = useState(0);
  const [myData, setMyData] = useState([]);

  const [selected, setSelected] = useState({
    name: "Todos",
    value: 0,
  });

  const handleData = (myNewData, selected) => {
    const subt = myNewData.reduce((prev, current) => prev + current.value, 0);
    setTotal(subt);
    setMyData(myNewData);
    setSelected({
      name: selected,
      value: subt,
    });
    console.log({ myNewData });
  };

  useEffect(() => {
    handleData(data, "Todos");
  }, [data]);

  const [selectedAge, setSelectedAge] = useState("Todos");

  const handleChange = (event) => {
    setSelectedAge(event.target.value);
    if (event.target.value === "Todos") {
      return handleData(data, event.target.value);
    } else {
      const mySelectedData = data.filter((element) => {
        const date = new Date(element.investmentDate).getFullYear();
        console.log({ date, selectedDate: event.target.value });
        return date === event.target.value;
      });
      handleData(mySelectedData, event.target.value);
    }
  };

  if (data.length === 0) {
    return <></>;
  }

  return (
    <Grid size={{ xs: 11, md: 6, lg: 5 }}>
      <Grid container justifyContent={"center"}>
        <Grid size={{ xs: 11, md: 10, lg: 9 }}>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Box sx={{ minWidth: 120 }}>
              <TextField
                select
                label="Fecha"
                disabled={false}
                value={selectedAge}
                onChange={handleChange}
                sx={{ py: 0 }}
              >
                {ages.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
          {myData.length > 0 ? (
            <Box display={"grid"}>
              <Box sx={{ gridArea: "1/1" }}>
                <ResponsiveContainer width="100%" height={200}>
                  <PieChart>
                    <Pie
                      data={myData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={80}
                      outerRadius={100}
                      fill="#82ca9d"
                      on
                    >
                      {myData.map((entry, index) => (
                        <Cell
                          fill={
                            COLORS[Math.floor((index / myData.length) * 10)]
                          }
                          key={entry.name}
                          onClick={(e) => {
                            setSelected(entry);
                          }}
                        />
                      ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                  </PieChart>
                </ResponsiveContainer>
                <Typography
                  variant="caption"
                  component={"p"}
                  textAlign={"center"}
                >
                  Moneda en {isUSD ?? false ? "USD" : "MXN"}
                </Typography>
              </Box>
              <Box
                sx={{ gridArea: "1/1" }}
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
                alignItems={"center"}
              >
                {data.length < 1 ? (
                  <Box>
                    <Typography
                      variant="body2"
                      component={"p"}
                      textAlign={"center"}
                    >
                      No hay data
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Typography
                      variant="h5"
                      fontWeight={"bold"}
                      textAlign={"center"}
                    >
                      {((selected?.name ?? "Inversion")?.length ?? 0) < 10
                        ? selected?.name ?? "Inversion"
                        : (selected?.name ?? "Inversion")
                            ?.substring(0, 10)
                            .concat("...") ?? ""}
                    </Typography>
                    <Typography
                      variant="body2"
                      component={"p"}
                      textAlign={"center"}
                    >
                      {
                        currencyFormatter
                          .format(selected?.value ?? "0")
                          .split(".")[0]
                      }
                    </Typography>
                    <Divider></Divider>
                    <Typography variant="body1" textAlign={"center"}>
                      {(((selected?.value ?? 0) / total) * 100).toFixed(2)}%
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Box
              width={"100%"}
              minHeight={200}
              display={"flex"}
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Box width={"100%"}>
                <Typography
                  variant="body2"
                  component={"p"}
                  textAlign={"center"}
                >
                  Sin proyectos
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ProjectsPieChart;
