import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from "../slices/errorsSlice";
import { startLoading, endLoading } from "../slices/loadingSlice";
import { setDollar } from "../slices/currencySlice";
import { getCurrencyDollar } from "../api/currencyApi";

export const getCurrencyActions = createAsyncThunk(
  "auth/getcurrencyactions",
  (_, { dispatch }) => {
    dispatch(startLoading());

    getCurrencyDollar()
      .then((data) => {
        console.log({ currency: data });
        const dato = data?.bmx?.series[0]?.datos[0] ?? {
          fecha: "03/09/2024",
          dato: "19.8335",
        };
        dispatch(setDollar(dato));
      })
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

// .then((response) =>
//   response.status === 200 ? response.json() : Promise.reject(response)
// )
