import {
  Typography,
  Card,
  CardContent,
  Grid,
  IconButton,
  DialogContent,
  Box,
  Divider,
  // TextField,
  DialogActions,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ProjectDetailsImagesCarousel from "./ProjectDetailsImagesCarousel";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import ProjectDetailsGraph from "./ProjectDetailsGraph";
import { currencyFormatter } from "../../../../utils/formatUtils";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";

const styles = {
  inputWrapper: {
    backgroundColor: "#ffffff",
    borderRadius: 4,
  },
  bgImage: {
    backgroundImage: "url(/img/bgidef.png)",
    backgroundPosition: "center bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
  },
  inputWrapper2: {
    margin: "0.7rem 1rem",
    border: 0,
    backgroundColor: "#ffffff",
    borderRadius: 4,
  },
  content0: {
    height: 500,
  },

  content: {
    paddingY: 0,
    width: 759,
    backgroundColor: "#ffffff",
    borderRadius: 8,
    textAlign: "center",
  },
  content2: {
    paddingY: 0,
    height: 50,
    width: 1500,
    backgroundColor: "#ffffff",
    borderRadius: 8,
  },
  submit: {
    textAlign: "center",
  },
  title: {
    flex: 1,
    color: "#2196f3",
  },

  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  container: {
    display: "block",
  },
  content3: {
    paddingY: 0,
    width: {
      md: "60vw",
    },
  },
  title2: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "Roboto Helvetica Arial sans-serif",
    fontWeight: "800",
  },
  fieldWrapper: {
    px: "1rem",
  },
  image: {
    height: "350px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  actions2: {
    paddingRight: "1rem",
  },
};

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <Grid container justifyContent="left">
        <Grid item>{children}</Grid>
      </Grid>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ProjectDetailsDescription = (props) => {
  const current = useSelector((state) => state.investments.current);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <Card elevation={0}>
        <CardContent sx={{ px: 0 }}>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{ marginTop: "0.5rem", color: "#9e9e9e" }}
          >
            Valor de adquisición
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            sx={{ marginBottom: "1rem" }}
          >
            <img src="/img/money.png" alt="money" />
            {
              currencyFormatter
                .format(current[0]?.projectRef?.investment)
                .split(".")[0]
            }
          </Typography>
          <ProjectDetailsImagesCarousel images={props.images} />
          <div>
            <Button
              sx={{
                bgcolor: "#FFFFFF",
                color: "#3D6076",
                textDecoration: "underline",
                textUnderlineOffset: "0.5rem",
                boxShadow: 0,
                height: 25,
                borderRadius: "1rem",
                ":hover": {
                  color: "whitesmoke",
                },
              }}
              onClick={handleClickOpen}
            >
              Descripción del proyecto
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>
                <Box sx={styles.title2}>
                  <Typography variant="h5">Descripción</Typography>
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </Box>
                <Divider />
              </DialogTitle>
              <DialogContent sx={styles.content3}>
                <Grid container>
                  <Grid item xs={12}>
                    {/* <StyledTextField
                      variant="standard"
                      multiline
                      rows={5}
                      label="Descripción"
                      name="description"
                      value={current[0]?.projectRef?.description}
                      sx={{ color: "green" }}
                    /> */}
                    {current[0]?.projectRef?.description
                      .split("\n")
                      .map((textLine) => {
                        if (textLine.includes("http")) {
                          return (
                            <Link
                              href={textLine}
                              target="_blank"
                              key={textLine}
                            >
                              <Typography variant="body1" component={"p"}>
                                {textLine}
                              </Typography>
                            </Link>
                          );
                        }
                        return (
                          <Typography
                            variant="body1"
                            component={"p"}
                            sx={{ color: "black" }}
                            key={textLine}
                          >
                            {textLine}
                          </Typography>
                        );
                      })}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={styles.actions2}>
                <Button onClick={handleClose}>Cerrar</Button>
              </DialogActions>
            </Dialog>
          </div>
          <ProjectDetailsGraph />
        </CardContent>
      </Card>
    </>
  );
};

export default ProjectDetailsDescription;
