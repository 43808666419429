import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';


const firebaseConfig = {
    apiKey: "AIzaSyBh95FG8T6LM4MTuMjaKSesOvbGy05EIss",
    authDomain: "ide-fund-develop.firebaseapp.com",
    projectId: "ide-fund-develop",
    storageBucket: "ide-fund-develop.appspot.com",
    messagingSenderId: "267898527778",
    appId: "1:267898527778:web:f83d5f4e173f6dd039ea7b",
    measurementId: "G-V74DME8JWY",
};


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firebaseAnalytics = getAnalytics(firebaseApp);


export {
  firebaseApp,
  firebaseAuth,
  firebaseAnalytics,
}