import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function ChangePasswordPage() {
  const navigate = useNavigate();
  return (
    <Container>
      <Typography variant="h3" fontWeight={"bold"} my={2}>
        Cambiar contraseña
      </Typography>
      <Box display={"flex"} justifyContent={""}>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancelar
        </Button>
      </Box>
      <Grid2 container justifyContent={"center"} spacing={2}>
        <Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 6 }}>
          <Card elevation={2} sx={{ borderRadius: "1rem" }}>
            <CardContent></CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Container>
  );
}

export default ChangePasswordPage;
